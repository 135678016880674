export const ADD_ITEM = 'ADD_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const REMOVE_ITEMS = 'REMOVE_ITEMS';
export const TRUNCATE_ITEMS = 'TRUNCATE_ITEMS';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const SET_ITEMS = 'SET_ITEMS';
export const SELECT_ITEM = 'SELECT_ITEM';
export const UNSELECT_ITEM = 'UNSELECT_ITEM';
export const UNSELECT_ALL_ITEM = 'UNSELECT_ALL_ITEM';
export const SET_PRG = 'SET_PRG';
export const DELETE_PRG = 'DELETE_PRG';
export const SET_USER = 'SET_USER';
export const STEP_BACK = 'STEP_BACK';
export const ADD_HISTORY_POINT = 'ADD_HISTORY_POINT';

export interface myStoreState {
    items: Item[]
    selectedItems: Item[]
    prg: Prg | null
    user: string | null
    actionHistory: string[]
}

export interface Prg {
    id: number,
    title: string
}

export interface ActionHistory {
    items: Item[]
}

export interface Item {
    id: number,
    el_type: string,
    el_sub_type: string,
    tag: string,
    text: string,
    left: number,
    top: number,
    width: number,
    height: number,
    label: string,
    selected: boolean,
    children: Item[],
    classNames: string[],
    data_source: string,
    icon: string,
    css: string,
    link: string,
}

interface AddItemAction {
    type: typeof ADD_ITEM,
    payload: Item
}

export interface RemoveItemAction {
    type: typeof REMOVE_ITEM,
    payload: Item
}

export interface RemoveItemsAction {
    type: typeof REMOVE_ITEMS,
    payload: Item[]
}

interface TruncateItemsAction {
    type: typeof TRUNCATE_ITEMS
}

export interface UpdateItemAction {
    type: typeof UPDATE_ITEM,
    payload: Item
}

interface SetItemsAction {
    type: typeof SET_ITEMS,
    payload: Item[]
}

interface SelectItemAction {
    type: typeof SELECT_ITEM,
    payload: Item
}

export interface UnSelectItemAction {
    type: typeof UNSELECT_ITEM,
    payload: Item
}

export interface UnselectAllItemAction {
    type: typeof UNSELECT_ALL_ITEM
}

export interface setPrgAction {
    type: typeof SET_PRG
    payload: Prg
}

export interface deletePrgAction {
    type: typeof DELETE_PRG
}

export interface setUserAction {
    type: typeof SET_USER
    payload: string
}

export interface stepBack {
    type: typeof STEP_BACK
}

export interface addHistoryPoint {
    type: typeof ADD_HISTORY_POINT
}

export type ItemActionTypes = AddItemAction | 
                              RemoveItemAction | 
                              TruncateItemsAction | 
                              UpdateItemAction | 
                              SetItemsAction |
                              SelectItemAction |
                              UnSelectItemAction |
                              UnselectAllItemAction |
                              RemoveItemsAction |
                              setPrgAction |
                              deletePrgAction |
                              setUserAction |
                              stepBack |
                              addHistoryPoint

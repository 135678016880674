import { create } from 'domain';
import React from 'react'
import { Provider } from 'react-redux'
import { createStore, combineReducers } from 'redux'
import { itemReducer } from './reducers';

const rootReducer = combineReducers({
    items: itemReducer,
});

export type RootState = ReturnType<typeof rootReducer>

export  const store = createStore(rootReducer)

export default store
import {
    ADD_HISTORY_POINT,
    ADD_ITEM,
    Item,
    ItemActionTypes,
    Prg,
    REMOVE_ITEM,
    REMOVE_ITEMS,
    SELECT_ITEM,
    SET_ITEMS,
    SET_PRG,
    SET_USER,
    STEP_BACK,
    TRUNCATE_ITEMS,
    UNSELECT_ALL_ITEM,
    UNSELECT_ITEM,
    UPDATE_ITEM,
} from './types'

export function addItem(newItem: Item): ItemActionTypes {
    return {
        type: ADD_ITEM,
        payload: newItem
    }
}

export function updateItem(item: Item): ItemActionTypes {
    return {
        type: UPDATE_ITEM,
        payload: item
    }
}

export function removeItem(item: Item): ItemActionTypes {
    return {
        type: REMOVE_ITEM,
        payload: item
    }
}

export function removeItems(items: Item[]) : ItemActionTypes {
    return {
        type: REMOVE_ITEMS,
        payload: items
    }
}

export function truncateItems(): ItemActionTypes {
    return {
        type: TRUNCATE_ITEMS
    }
}

export function setItems(items: Item[]): ItemActionTypes {
    return {
        type: SET_ITEMS,
        payload: items
    }
}

export function selectItem(item: Item) : ItemActionTypes {
    item.selected = true;
    return {
        type: SELECT_ITEM,
        payload: item
    }
}

export function unSelectItem(item: Item) : ItemActionTypes {
    item.selected = false;
    return {
        type: UNSELECT_ITEM,
        payload: item
    }
}

export function unSelectAllItems() : ItemActionTypes {
    return {
        type: UNSELECT_ALL_ITEM
    } 
}
export function setPrg(prg: Prg) : ItemActionTypes {
    return {
        type: SET_PRG,
        payload: prg
    }
}
export function setUser(user: string) : ItemActionTypes {
    return {
        type: SET_USER,
        payload: user
    }
}
export function stepBack() : ItemActionTypes {
    return {
        type: STEP_BACK
    }
}
export function addHistoryPoint() : ItemActionTypes {
    return {
        type: ADD_HISTORY_POINT
    }
}
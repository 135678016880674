import { get } from 'https';
import store from '../stores/stores';
import { exit } from 'process';
import { ItemTypes } from '../components/PseudoFormElementTypes';
import {Item} from './types';


export function updateItemsArray(payload: Item, items: Item[]) : Item[] {
    items.forEach((item: Item) => {
        
        let key = items.indexOf(item);
        if(item.id === payload.id) {
            item = payload;
        }else if(item.children.length > 0) {
            item.children = updateItemsArray(payload, item.children)
        }

        items[key] = item;

    });

    return items;
} 

export function removeElementFromArray(payload: Item, items: Item[]) : Item[] {
    
    items.some((item: Item) => {
        
        let key = items.indexOf(item);
        
        if(item.id === payload.id) {
            items.splice(key, 1);
            return true;
        }else if(item.children.length > 0) {
            item.children = removeElementFromArray(payload, item.children);
            items[key] = item;
        }

    });

    return items;
}

export function findItemInArrayById(itemId: number, items: Item[]) :  Item | null {
    
    var returnItem: Item | null = null;

    items.some((item: Item) => {
        if(itemId === item.id) {
            returnItem = item;
            return true;
        }else {
            if(item.children.length > 0) {
                var found: Item | null = findItemInArrayById(itemId, item.children);
                if(found !== null) {
                    returnItem = found;
                    return true;
                } 
            }
        }
    });

    return returnItem;
}

export function findClosestParentItem(needle: Item, items: Item[], parentItem: Item | null = null): null | Item {

    var parent = null;
    
    items.some((item: Item) => {
        if(item.id === needle.id) {
            return parent = parentItem;
        }else if(item.children.length > 0) {
            return parent = findClosestParentItem(needle, item.children, item);
        }
    });

    return parent;
}



export function createEmptyItem(id: number, el_type: string, el_subtype: string) : Item {
    
    return {
        id: id,
        el_type: el_type,
        el_sub_type: el_subtype,
        tag: 'div',
        classNames: [],
        text: '',
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        label: '',
        selected: false,
        children: [],
        data_source: '',
        icon: '',
        css: '',
        link: ''
    };
}


export function getMaxItemId(items: Item[], id: number = 0) : number {
    
    items.forEach((item: Item) => {
        if(item.id > id) {
            id = item.id;
        }

        if(item.children.length > 0) {
            id = getMaxItemId(item.children, id);
        }
    });

    return id;
}

export function isDropable(sourceType: string, targetType:string) {

}

export {};
import React from 'react';
import store from "./stores/stores";

import PseudoForm from './components/PseudoForm';
import ActionMenu from './components/ActionMenu';
import ExportBox from './components/ExportBox';
import PrgBox from "./components/PrgBox";


function App() {

  return (
    <div className="App">
      <header className="App-header">
        <h1>Form builder <PrgBox /></h1>
        <div className="menuBar">
          <ActionMenu />
        </div>
      </header>
      <PseudoForm />
      <ExportBox />
    </div>
  );
}

export default App;

import React from 'react';
import store from '../stores/stores';
import {Prg} from "../stores/types";
import {truncateItems, setItems, unSelectAllItems, setPrg} from '../stores/actions';
import config from "../config";


export default class OpenForm extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            prg: 0
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange = (event: any) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        //console.log(this.state);
    }

    submit = (event: any) => {
        event.preventDefault();

        if(this.state.prg != 0) {
            fetch(config.baseUri +'/api/form/open/' + this.state.prg)
                .then(res => res.json())
                .then(
                    (result) => {
                        store.dispatch(unSelectAllItems());
                        store.dispatch(truncateItems());
                        store.dispatch(setItems(result.structure));
                        store.dispatch(setPrg(result.prg));
                    },
                    (error) => {
                        this.setState({
                            ...this.state,
                            error
                        });
                    }
                )
        }

    }


    render() {

        let opts: JSX.Element[] = [];


        this.props.prgs.forEach((opt: any) => {
           opts.push(<option value={opt.prg} key={"prg_" + opt.prg}>{opt.text}</option>);
        });

        return (
            <div>
            <form name="importForm" onSubmit={(event: any) => this.submit(event)}>
                <div className="form-group">
                    <select name="prg" onChange={(event:any) => this.handleInputChange(event)} value={this.state.prg} className="form-control">
                        <option value={0} key='prg_0'>=== vyberte formulář pro otevření ===</option>
                        {opts}
                    </select>
                </div>
                <div className="form-group">
                    <button type="submit">Otevřít</button>
                </div>
            </form>
            </div>
        );

    }

}
import React from 'react';
import store from '../stores/stores';
import {Item} from "../stores/types";


export default class ModalWindow extends React.Component<any, any> {

    render() {
        return (
            <div className='modalHolder'>
            <div className='modal'>
                <div className='cover' onClick={(event) => this.props.toggleFunction(event)}>
                </div>
                <div className='wrapper'>
                    <div className='header'>
                        {this.props.title}
                        <a href="#" onClick={(event) => this.props.toggleFunction(event)}>
                            [x]
                        </a>
                    </div>
                    <div className='body'>
                        {this.props.children}
                    </div>
                </div>
            </div>
            </div>
        );
    }

}
import {
    ADD_HISTORY_POINT,
    ADD_ITEM,
    Item,
    ItemActionTypes,
    myStoreState,
    REMOVE_ITEM,
    REMOVE_ITEMS,
    SELECT_ITEM,
    SET_ITEMS,
    SET_PRG,
    SET_USER,
    STEP_BACK,
    TRUNCATE_ITEMS,
    UNSELECT_ALL_ITEM,
    UNSELECT_ITEM,
    UPDATE_ITEM
} from './types'
import {removeElementFromArray, updateItemsArray} from './helpers';


const initialState: myStoreState = {
    items: [] as Item[],
    selectedItems: [] as Item[],
    prg: null,
    user: null,
    actionHistory: [] as string[]
}

function addActionHistoryPoint(state: myStoreState) : string[] {
    let history = state.actionHistory;
    history = [...history, JSON.stringify(state.items)];
    return history ;
}

function popHistory(state: myStoreState) : myStoreState {

    if(state.actionHistory.length > 0) {
        let point: string|undefined = state.actionHistory.pop();
        if(point !== undefined) {
            let new_state : myStoreState = {
                ...state,
                items: JSON.parse(point),
                selectedItems: [] as Item[],
            }
            return new_state
        }
    }
    return state;
}

function actionUnSelectAllItems(state: myStoreState) : myStoreState {

    var items = state.items;
    state.selectedItems.forEach((item: Item) => {
        item.selected = false;
        items = updateItemsArray(item, items);
    });

    return {
        ...state,
        items: items, 
        selectedItems: [] as Item[]
    }
}

function actionRemoveItems(payload: Item[], state: myStoreState) : myStoreState {

    var items = state.items;

    payload.forEach((item: Item) => {
        items = removeElementFromArray(item, items);
    });

    return {
        ...state,
        items: items,
        selectedItems: []
    }

}

export function itemReducer(state = initialState, action: ItemActionTypes) : myStoreState {
    switch (action.type) {
        case ADD_ITEM:
            return {
                ...state,
                //selectedItems: state.selectedItems,
                items: [...state.items, action.payload],
            }
        case SET_ITEMS:
            return {
                ...state,
                selectedItems: [] as Item[],
                items: action.payload,
                actionHistory: [],
            }            
        case UPDATE_ITEM:
            return {
                ...state,
                //selectedItems: state.selectedItems,
                items: updateItemsArray(action.payload, state.items),
            }
        case REMOVE_ITEM:
            return {
                ...state,
                selectedItems: state.selectedItems,
                items: removeElementFromArray(action.payload, state.items),
            }
        case TRUNCATE_ITEMS:
            return {
                ...state,
                items: [] as Item[],
                selectedItems: [] as Item[],
                //actionHistory: [] as ActionHistory[],
            }
        case REMOVE_ITEMS: 
            return actionRemoveItems(action.payload, state);
        case SELECT_ITEM: {
            action.payload.selected = true;
            return { 
                ...state,
                items: updateItemsArray(action.payload, state.items),
                selectedItems: [...state.selectedItems, action.payload]
            };
        }
        case UNSELECT_ITEM: {
            action.payload.selected = false;
            return { 
                ...state,
                items: updateItemsArray(action.payload, state.items),
                selectedItems: removeElementFromArray(action.payload, state.selectedItems)
            };
        }
        case UNSELECT_ALL_ITEM: {
            return actionUnSelectAllItems(state);
        }
        case SET_PRG: {
            return {
                ...state,
                prg: action.payload
            }
        }
        case SET_USER: {
            return {
                ...state,
                user: action.payload
            }
        }
        case STEP_BACK: {
           return popHistory(state)
        }

        case ADD_HISTORY_POINT: {
            return {
                ...state,
                actionHistory: addActionHistoryPoint(state)
            }
        }
        default:
            return state
    }
}

import React from 'react';
import { textChangeRangeIsUnchanged } from 'typescript';

import store from '../stores/stores';

export default class ExportBox extends React.Component<any, any> {
    
    constructor(props: any) {
        super(props);

        this.state = {
            visible: false,
        };
        
        store.subscribe(() => {
            
            this.state = {
                visible: false,
            };
    
            this.setState(this.state)
        });

    }

    onChange = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
    }

    toggleVisibility = (event: any) => {
        event.preventDefault();
        if(this.state.visible) {
            this.state = {
                visible: false
            }
        }else {
            this.state = {
                visible: true
            }
        }

        this.setState(this.state);
    }

    render() {
        let className: string = "hidden";

        if(this.state.visible) {
            className = "visible";
        }

        return (
            <div id="exportBox"
                onClick={(event: any) => this.toggleVisibility(event)}
                className={className}
            >
                <textarea value={JSON.stringify(store.getState().items.items, null, "    ")} 
                onChange={(event: any) => this.onChange(event)} 
                onClick={(event: any) => this.onChange(event)}
                 />
            </div>
        )
    }

}
import React from 'react';
import PseudoFormElement from './PseudoFormElement';
import PseudoFormElementDraggable from './PseudoFormElementDraggable';
import PseudoFormElementDroppable from './PseudoFormElementDroppable';
import store from '../stores/stores';
import {Item, ADD_ITEM, TRUNCATE_ITEMS} from '../stores/types';
import {addItem, truncateItems, setItems, setUser, unSelectAllItems, setPrg} from '../stores/actions';
import { ItemTypes } from './PseudoFormElementTypes';
import WindowElement from './WindowElement';
import InputWindow from './InputWindow';
import Column from './Column';
import Row from './Row';
import config from "../config";

import { createEmptyItem, findClosestParentItem, findItemInArrayById, getMaxItemId } from '../stores/helpers';


export default class PseudoForm extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        
        this.state = {
            error: null,
            isLoaded: false,
            items: [] as Item[],
            form_objects: []
        }

        
        store.subscribe(() => {
            this.setItemsToState()
        }); 
        
    }

    setItemsToState() {
        this.state = {
            error: null,
            isLoaded: true,
            items: store.getState().items.items,
            form_objects: []
        };
        this.setState(this.state);
    }


    componentDidMount() {

        fetch(config.baseUri + '/api/session')
            .then(res => res.json())
            .then(
                (result) => {
                    store.dispatch(setUser(result.user));
                    sessionStorage.setItem("allowImport", result.allowImport);
                    sessionStorage.setItem("allowOpen", result.allowOpen);

                    if(result.prg > 0) {
                        var uri = "";
                        if(result.action === 'none') {
                            return;
                        }else if(result.action === 'import') {
                            var uri = config.baseUri + '/api/structure/' + result.prg;
                        } else if(result.action === 'open') {
                            var uri = config.baseUri +'/api/form/open/' + result.prg;
                        }
                        fetch(uri)
                            .then(res => res.json())
                            .then(
                                (result) => {
                                    store.dispatch(unSelectAllItems());
                                    store.dispatch(truncateItems());
                                    store.dispatch(setItems(result.structure));
                                    store.dispatch(setPrg(result.prg));
                                },
                                (error) => {
                                    alert('ID ' + result.prg + ' pro akci ' + result.action + ' nenalezeno');
                                    this.setState({
                                        ...this.state,
                                        error
                                    });
                                }
                            )
                    }
                },
                (error) => {}
            );


        var win = createEmptyItem(0, 'html', 'window')
        store.dispatch(addItem(win));

        this.setState({
            ...this.state,
            isLoaded: true,
            error:null
        });
    }

    buildElement = (item: Item, siblings_count: number = 0) => {

        var children: JSX.Element[] = [];
        var children_count = item.children.length;
        
        if(children_count > 0) {
            item.children.forEach((child: Item) => {  
                children.push(this.buildElement(child, children_count));
            });
        }

        var props = {
            className: item.classNames.join(' '),
            children: children,
            item: item, 
            key: item.id.toString(),
            siblings_count: siblings_count
        };
        
        switch(item.el_type) {
            case 'static' :
                return React.createElement(PseudoFormElementDraggable, props);
            case 'edit' :
            case 'push_button':
            case 'check_box':
            case 'combo_box':
            case 'image':
                return React.createElement(PseudoFormElementDroppable, props);
            case 'html' : {
                switch(item.el_sub_type) {
                    case 'inputw':
                        return React.createElement(InputWindow, props);
                    case 'window':
                        return React.createElement(WindowElement, props);
                    case 'row':
                        return React.createElement(Row, props);
                    case 'column':
                        return React.createElement(Column, props);
                    default: {
                        var CustomTag = `${item.tag}` as keyof JSX.IntrinsicElements;
                        return React.createElement(CustomTag, props);
                    }
                }
            }
            default: {
                return React.createElement(PseudoFormElement, props);
            }  
        }
    }

    render() {        
        //const {error, isLoaded,  items} = this.state;
        const error = this.state.error;
        const isLoaded = this.state.isLoaded;
        const items = this.state.items;

        if(error) {
            return <div className="error">Error: {error.message}</div>;
        }else if(!isLoaded) {
            return <div className="loader">Loading ...</div>;
        }else {            

            items.forEach((item: Item) => {    
                this.state.form_objects.push(this.buildElement(item));
            });

            return (
                
                    <section className="pseudo_form_container">
                        {this.state.form_objects}
                    </section>

            ); 
        }
    }
}
import React from 'react';
import PseudoFormElement from './PseudoFormElement';
import ModalWindow from "./modalWindow";
import OpenForm from "../forms/OpenForm";
import ElementAttributeForm from "../forms/ElementAttributeForm";

export default class PseudoFormElementDraggable extends PseudoFormElement
{

    constructor(props: any) {
        super(props);
        this.addClass('draggable');
    }
    
    onDragStart = (event: any, itemId: string) => {
    	event.dataTransfer.setData("itemId", itemId);
    }
    
    render() {
        return (
            <div className={this.getClassNames()}
                  style={this.getStyle()} 
                  data-id={this.props.item.id} 
                  draggable
                  onDragStart= {(event: any) => this.onDragStart(event, this.props.item.id)}
                  onClick={(event) => this.onClickHandler(event, this.props.item.id)}
                  onDoubleClick={(event) => this.onDoubleClickHandler(event, this.props.item.id)}
                  key={this.props.item.id.toString()} 
                  >
                {this.writeMetas(this.props.item.id)}
                {this.writeText(this.props.item.el_type,this.props.item.text)}
                {this.writeLabel(this.props.item.label)}

                {this.state.modalVisibility == true &&
                <ModalWindow
                    title="Upravit vlastnosti"
                    toggleFunction={this.toggleWindow}
                >
                    <ElementAttributeForm item={this.props.item} toggleWindow={this.toggleWindow}/>
                </ModalWindow>
                }
            </div>
        );

    }

}
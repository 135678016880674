import React from 'react';
import ModalWindow from "./modalWindow";
import store from '../stores/stores';
import {Item} from "../stores/types";
import OpenForm from "../forms/OpenForm";
import config from "../config";


export default class OpenDataButton extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            visible: false,
            prgs: []
        }

        store.subscribe(() => {

            this.state = {
                visible: false,
            };

            this.setState(this.state)
        });

    }

    loadData = () => {
        fetch(config.baseUri + '/api/form/list')
            .then(res => res.json())
            .then(
                (result) => {
                    let state = { ...this.state }
                    state.prgs = result;
                    this.state = state;
                    this.setState(this.state);
                },
                (error) => {
                    console.log(error)
                    /*this.setState({
                        ...this.state,
                        isLoaded: true,
                        error
                    });*/
                }
            )
    }

    toggleWindow = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        this.loadData();

        let state = { ...this.state }
        if(this.state.visible == false) {
            state.visible = true;
        }else {
            state.visible = false;
        }

        this.state = state;
        this.setState(this.state);
    }

    render() {

        return (
            <div className="buttonContainer">
            <button
                onClick={(event: any) => {this.toggleWindow(event)}}
            >{this.props.children}</button>

            {this.state.visible == true &&
                <ModalWindow
                    title="Otevřít formulář"
                    toggleFunction={this.toggleWindow}
                >
                    {this.state.prgs.length == 0 &&
                        <span>Zatím není nic k otevření.</span>
                    }
                    {this.state.prgs.length > 0 &&
                    <OpenForm prgs={this.state.prgs} />
                    }
                </ModalWindow>
            }
            </div>
        );
    }


}
import React, {RefObject, useRef } from 'react';
import store from '../stores/stores';
import {Item, ADD_ITEM} from '../stores/types';
import {addHistoryPoint, removeItem, selectItem, unSelectItem, updateItem} from '../stores/actions';
import { createEmptyItem, findClosestParentItem, findItemInArrayById, getMaxItemId } from '../stores/helpers';
import ModalWindow from "./modalWindow";
import ElementAttributeForm from "../forms/ElementAttributeForm";


export default class StructureElement extends React.Component<any, any> {

    public class_names: String[] = [];

    public ref: RefObject<any>;

    constructor(props: any) {
        super(props);

        this.addClass('draggable');

        this.ref = React.createRef();

        this.state = {
            dropVisible: false,
            modalVisibility: false,
        }
        /*
        store.subscribe(() => {
            this.state = {};
            this.setState(this.state);
        });

         */

    }

    componentDidMount() {
        this.setState(this.state);
    }

    addClass(class_name: string) {
        this.class_names = [...this.class_names, class_name];
    }

    removeClass(class_name: string) {
        var index = this.class_names.indexOf(class_name);
        this.class_names.splice(index, 1);
    }

    getClassNames(class_names: String[] = []) {
        class_names = [this.props.item.el_sub_type, ...this.class_names, ...class_names];
        if(this.props.item.selected === true) {
            class_names = [...class_names, 'in_selection'];
        }
        return `${class_names.join(' ')}`;
    }


    onDragStart = (event: any, itemId: string) => {
    	event.dataTransfer.setData("itemId", itemId);
    }

    onMouseOver = (event:any) => {
        event.stopPropagation();
        event.preventDefault();
        this.state = {
            dropVisible: false,
        }

        this.setState(this.state);
        event.target.classList.remove("dragover");
    }

    showDrop = (event: any) => {
        event.target.classList.add("dragover");
        this.state = {
            dropVisible: true,
        }
        this.setState(this.state);
    }

    onDragOver = (event: any) => {
        event.stopPropagation();
        event.preventDefault();

        let el_rect = this.ref.current.getBoundingClientRect();
        let drop_end_x = el_rect.x + el_rect.width;
        let drop_end_y = el_rect.y + el_rect.height;

        if(event.clientX > el_rect.x && event.clientX < drop_end_x && event.clientY > el_rect.y && event.clientY < drop_end_y) {
            this.showDrop(event);
        }

        //console.log(event);

    }

    onDragLeave =(event: any) => {

        event.stopPropagation();
        event.preventDefault();

        let el_rect = this.ref.current.getBoundingClientRect();
        let drop_end_x = el_rect.x + el_rect.width;
        let drop_end_y = el_rect.y + el_rect.height;

        if( !(event.clientX > el_rect.x && event.clientX < drop_end_x && event.clientY > el_rect.y && event.clientY < drop_end_y ) ) {
            event.target.classList.remove("dragover");
            this.state = {
                dropVisible: false,
            }

            this.setState(this.state);
        }


    }

    onDrop = (event: any, direction: string) => {
        store.dispatch(addHistoryPoint());

        event.target.classList.remove("dragover");		
        
        let dragItemId = parseInt(event.dataTransfer.getData("itemId"));

        var parent = findClosestParentItem(this.props.item, store.getState().items.items);
        
        if(parent !== null) {
            var sorted_items: Item[] = [];

            var movedItem: Item | null = findItemInArrayById(dragItemId, store.getState().items.items);

            if(movedItem !== null) {
                if(movedItem.el_type == this.props.item.el_type && movedItem.el_sub_type == this.props.item.el_sub_type) {
                //if(true) {
                //zatřídění elementu pod rodiče cíle
                    parent.children.forEach((item: Item) => {
                    if(movedItem !== null) {
                            if(movedItem.id === item.id) {
                                //je to stejný element, nedělá se nic 
                            }else if(this.props.item.id == item.id) {
                                if(direction === 'before') {
                                    sorted_items.push(movedItem);
                                    sorted_items.push(item);
                                }else {
                                    sorted_items.push(item);
                                    sorted_items.push(movedItem);
                                }
                            }else {
                                sorted_items.push(item);
                            }
                        }else {
                            console.debug('moved_item is null');
                        }
                    });

                    var movedItemParent: Item | null = findClosestParentItem(movedItem, store.getState().items.items)
                
                    if(movedItemParent != parent) { //pokud se nejedná o stejné rodiče odstraníme původní element včetně potomků
                        store.dispatch(removeItem(movedItem));
                    }

                    parent.children = sorted_items;
                    store.dispatch(updateItem(parent));
                }else {
                    alert('Řadit elementy "Před" a "Za" je možné pouze s elementy stejného typu');
                }

            } else {
                alert('nepodařilo se identifikovat prvek pro přesun');
            }
            
        }else {
            alert('Some error in sorting');
        }
        
        /*
        console.log("Source:" + dragItemId);
        console.log("Target:" + this.props.item.id);
        */
    }


    onDropInside = (event: any) => {
        store.dispatch(addHistoryPoint());
        event.target.classList.remove("dragover");

        let dragItemId = parseInt(event.dataTransfer.getData("itemId"));

        let dragItem = findItemInArrayById(dragItemId, store.getState().items.items);

        if(dragItem !== null) {
            if (this.props.item.el_type == 'html' && this.props.item.el_sub_type == 'column') {
                if (dragItem?.el_type == 'html' && dragItem.el_sub_type == 'column') {
                    alert('Buňka nelze vložit do buňky');
                } else {
                    store.dispatch(removeItem(dragItem));
                    this.props.item.children.push(dragItem);
                    //item.children.push(dragItem);
                    //store.dispatch(updateItem(item));
                }
            }else {
                alert('není to buňka');
            }
        }
    }

    onDoubleClickHandler = (event: any, itemId: number) => {
        this.toggleWindow(event);
    }

    toggleWindow = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        let state = { ...this.state }
        if(this.state.modalVisibility == false) {
            state.modalVisibility = true;
        }else {
            state.modalVisibility = false;
        }

        this.state = state;
        this.setState(this.state);
    }

    onClickHandler = (event: any, itemId: number) => {
        if(event.ctrlKey || event.metaKey) {
            var clickedItem: Item | null = findItemInArrayById(itemId, store.getState().items.items);
            var parent = findClosestParentItem(this.props.item, store.getState().items.items);

            if(clickedItem !== null) {
                if(findItemInArrayById(itemId, store.getState().items.selectedItems) === null) {
                    store.dispatch(selectItem(clickedItem));
                }else {
                    store.dispatch(unSelectItem(clickedItem));
                }
            }else {
                alert('Target element not found');
            }
        }
    }

    addElementOfSameType = (event: any, itemId: number) => {
        store.dispatch(addHistoryPoint());
        let parent = findClosestParentItem(this.props.item, store.getState().items.items);
        let maxItemId = getMaxItemId(store.getState().items.items);    
        let new_item = createEmptyItem(maxItemId + 1, this.props.item.el_type, this.props.item.el_sub_type);

        if(new_item.el_type == 'html' && new_item.el_sub_type == "row") {
            let column = createEmptyItem(maxItemId + 2, 'html', 'column');
            new_item.children.push(column);
        }

        let sorted_items: Item[] = [];
        if(parent != null) {
            parent.children.forEach((item: Item) => {
                sorted_items.push(item);

                if(this.props.item.id == item.id) {
                    sorted_items.push(new_item);
                }

            });
            parent.children = sorted_items;
            store.dispatch(updateItem(parent));

        }else {
            alert('Parent not found');
        }
       
    }

    render() {

        return (
            <div className={this.props.className}>
                {this.props.children}
                <div className="separator"></div>
            </div>
        );
    }

}
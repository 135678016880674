import React from 'react';
import store from '../stores/stores';
import {Item, Prg} from '../stores/types';

export default class PrgBox extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            prg: null,
        }

        //this.setState(this.state);


        store.subscribe(() => {
            this.changeState()
        });

    }

    changeState() {
        this.state = {
            prg: store.getState().items.prg
        };
        this.setState(this.state);
    }

    render() {


        if(this.state.prg !== null) {
            return (
                <em>{this.state.prg.title}</em>
            )
        }else {
            return "";
        }
    }


}
import React from 'react';
import store from '../stores/stores';
import {Item, Prg} from "../stores/types";
import {truncateItems, setItems, unSelectAllItems, setPrg, updateItem} from '../stores/actions';
import config from "../config";


export default class ElementAttributeForm extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            data_source: props.item.data_source,
            label: props.item.label,
            icon: props.item.icon,
            classNames: props.item.classNames.join(','),
            css: props.item.css,
            link: props.item.link,
            tag: props.item.tag,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange = (event: any) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        //console.log(this.state);
    }

    submit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        //console.log(this.props);


        let item: Item = this.props.item;

        item.data_source = this.state.data_source;
        item.css = this.state.css;
        item.label = this.state.label;
        item.icon = this.state.icon;

        item.link = this.state.link;
        item.tag = this.state.tag;

        let classNames = this.state.classNames.split(',');

        item.classNames = classNames.map(
                                function(str: string) {
                                    return str.trim()
                                }
                            );

        store.dispatch(updateItem(item));

        this.props.toggleWindow(event);
    }

    render() {
        return (
            <div>
                <h3>Element: {this.props.item.id}</h3>
                <form name="attributeForm" onSubmit={(event: any) => this.submit(event)}>
                    {this.props.item.el_type == 'html' &&
                        <div className="form-group">
                            <label>Html Tag</label>
                            <input type="text" name="tag" onChange={(event:any) => this.handleInputChange(event)} value={this.state.tag} className="form-control"/>
                        </div>
                    }
                    <div className="form-group">
                        <label>Data Source</label>
                        <input type="text" name="data_source" onChange={(event:any) => this.handleInputChange(event)} value={this.state.data_source} className="form-control"/>
                    </div>
                    <div className="form-group">
                        <label>Popisek</label>
                        <input type="text" name="label" onChange={(event:any) => this.handleInputChange(event)} value={this.state.label} className="form-control"/>
                    </div>
                    <div className="form-group">
                        <label>Ikona</label>
                        <input type="text" name="icon" onChange={(event:any) => this.handleInputChange(event)} value={this.state.icon} className="form-control"/>
                    </div>
                    <div className="form-group">
                        <label>Css třídy</label>
                        <input type="text" name="classNames" onChange={(event:any) => this.handleInputChange(event)} value={this.state.classNames} className="form-control"/>
                    </div>
                    <div className="form-group">
                        <label>Css styl</label>
                        <input type="text" name="classNames" onChange={(event:any) => this.handleInputChange(event)} value={this.state.css} className="form-control"/>
                    </div>
                    <div className="form-group">
                        <label>Link</label>
                        <input type="text" name="link" onChange={(event:any) => this.handleInputChange(event)} value={this.state.link} className="form-control"/>
                    </div>

                    <div className="form-group">
                        <button type="submit">Uložit</button>
                    </div>
                </form>
            </div>
        );
    }

}
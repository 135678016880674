import React from 'react';
import store from '../stores/stores';
import StructureElement from './StructureElement';

export default class WindowElement extends StructureElement {

    public class_names: String[] = [];

    constructor(props: any) {
        super(props);

        this.state = {};

        store.subscribe(() => {
            this.state = {};
            this.setState(this.state);
        });
    }


    render() {


        return (
            <div className={this.getClassNames()} >
                <span className="htmlType" key={"htmlType_" + this.props.item.id.toString()}>output</span>
                {this.props.children}
                <div className="separator" key={"separator_" + this.props.item.id.toString()}></div>
            </div>
        );
    }

}
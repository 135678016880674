import React from 'react';
import store from '../stores/stores';
import {Item, ADD_ITEM} from '../stores/types';
import { ItemTypes } from './PseudoFormElementTypes';
import {addItem, stepBack, removeItems, updateItem, addHistoryPoint} from '../stores/actions';
import { isConstructSignatureDeclaration } from 'typescript';
import { createEmptyItem, findClosestParentItem, findItemInArrayById, getMaxItemId } from '../stores/helpers';
import ImportDataButton from './ImportDataButton';
import OpenDataButton from "./OpenDataButton";
import config from "../config";


export default class ActionMenu extends React.Component<any, any>
{

    constructor(props: any) {
        super(props);

        this.state = {
            menu: [],
        };
        store.subscribe(() => {
            
            this.state = {
                menu: [],
            };

            this.setState(this.state)
        })
    }

    initState() {

    }

    stepBack = () => {
        store.dispatch(stepBack());
    }

    makeGroup = () => {
        store.dispatch(addHistoryPoint());
        var children = store.getState().items.selectedItems;

        console.log(children);

        var firstChild: Item | null = null;

        children.map((item: Item) => {
            
            if(firstChild === null) {
                firstChild = item;
            }

            item.selected = false;

        });

        var maxItemId = getMaxItemId(store.getState().items.items);    

        var column = createEmptyItem(maxItemId + 1, 'html', 'column');
        column.classNames = ['column'];
        column.children = children;

        var row = createEmptyItem(maxItemId + 2, 'html', 'row');
        row.classNames = ['row'];
        row.children = [column];

        if(firstChild !== null) {

            var parent = findClosestParentItem(firstChild, store.getState().items.items); 

            if(parent?.id == -1) {
                parent = findItemInArrayById(0, store.getState().items.items);
            }

            store.dispatch(removeItems(children)); //odeberu soucasne prvky ze store
            if(parent === null) {
                store.dispatch(addItem(row));
            }else {
                parent.children = [row, ...parent.children]
                store.dispatch(updateItem(parent));
            }

        } else {
            alert('selection is empty');
        }
    }

    makeJoin = () => {
        store.dispatch(addHistoryPoint());
        var children = store.getState().items.selectedItems;

        console.log(children);

        var firstChild: Item | null = null;

        children.map((item: Item) => {

            if(firstChild === null) {
                firstChild = item;
            }

            item.selected = false;

        });

        var maxItemId = getMaxItemId(store.getState().items.items);

        var column = createEmptyItem(maxItemId + 1, 'html', 'input_group');
        column.classNames = ['input_group'];
        column.children = children;

        if(firstChild !== null) {

            var parent = findClosestParentItem(firstChild, store.getState().items.items);

            if(parent?.id == -1) {
                parent = findItemInArrayById(0, store.getState().items.items);
            }

            store.dispatch(removeItems(children)); //odeberu soucasne prvky ze store
            if(parent === null) {
                store.dispatch(addItem(column));
            }else {
                parent.children = [column, ...parent.children]
                store.dispatch(updateItem(parent));
            }

        } else {
            alert('selection is empty');
        }

    }

    createRow = () => {
        store.dispatch(addHistoryPoint());
        var maxItemId = getMaxItemId(store.getState().items.items);  
        var column = createEmptyItem(maxItemId + 1, 'html', 'column');
        column.classNames = ['column'];

        var row = createEmptyItem(maxItemId + 2, 'html', 'row');
        row.classNames = ['row'];
        row.children = [column];

        var parent = findItemInArrayById(0, store.getState().items.items);

        if(parent === null) {
            alert("Parent element not found");
        }else {
            parent.children = [row, ...parent.children];
            store.dispatch(updateItem(parent));
        }

    }

    deleteSelected = () => {
        store.dispatch(addHistoryPoint());
        store.dispatch(removeItems(store.getState().items.selectedItems));
    }

    saveForm = () => {

        let prg = store.getState().items.prg;

        if(prg == null) {
            alert("není načten formulář, který by bylo možné uložit");
        }else {
            let prg = {
                id: store.getState().items.prg?.id,
                title: store.getState().items.prg?.title
            }
            let data = {
                structure: store.getState().items.items,
                prg: prg,
            }

            let requestOption = {
                method: 'PUT',
                headers: { 'Content-Type': 'plain/text' },
                body: JSON.stringify(data)
            }

            fetch(config.baseUri +'/api/form/save', requestOption)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result);
                        if(result.status == "OK") {
                            alert("Uloženo");
                        }else {
                            alert("Něco je špatně")
                            console.log(result);
                        }
                    },
                    (error) => {
                        alert("Došlo k chybě při ukládání");
                        console.log(error);
                    }
                );
        }
    }

    render() {
        if(store.getState().items.actionHistory.length != 0) {
            this.state.menu.push(<button onClick={() => this.stepBack()} key="back_btn">Zpět</button>)
        }

        if(sessionStorage.getItem('allowImport') === 'allow') {
            this.state.menu.push(<ImportDataButton key="import_button">Import</ImportDataButton>);
        }

        if(sessionStorage.getItem('allowOpen') === 'allow') {
            this.state.menu.push(<OpenDataButton key="open_button">Otevřít</OpenDataButton>);
        }

        if(store.getState().items.prg !== null) {
            this.state.menu.push(<button onClick={() => this.saveForm()} key="save_btn">Uložit</button>);
        }

        //this.state.menu.push(<button onClick={() => this.saveForm()}>Otevřít</button>);

        this.state.menu.push(<button onClick={(event: any) => this.createRow()} key="make_row">New row</button>);

        if(store.getState().items.selectedItems.length > 0) {
            this.state.menu.push(
                <button onClick={(event: any) => this.makeJoin()} key="make_join">Join</button>
            );
            this.state.menu.push(
                <button onClick={(event: any) => this.makeGroup()} key="make_group">Group</button>
            );
            this.state.menu.push(
                <button onClick={(event: any) => this.deleteSelected()} key="deleteItems">Delete</button>
            );
        }        


        return (
            <div className="menu" key="menu_bar">
                {this.state.menu}
            </div>
        );
    }
}
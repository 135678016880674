import React from 'react';
import store from '../stores/stores';
import StructureElement from './StructureElement';
import ModalWindow from "./modalWindow";
import ElementAttributeForm from "../forms/ElementAttributeForm";

export default class Column extends StructureElement {

    public class_names: String[] = [];

    render() {

        for(let i = 1; i < 25; i++ ) {
            this.removeClass('sc-'+i);
        }

        this.addClass('sc-'+this.props.siblings_count);
        
        return (
            <div className={this.getClassNames()}
                 ref={this.ref}
                 data-type={this.props.item.el_sub_type}
                 onDragOver={(event) => this.onDragOver(event)}
                 onDragLeave={(event)=>this.onDragLeave(event)}
                 onMouseOver={(event) => this.onMouseOver(event)}
            >
                <span className="addElement"
                    onClick={(event) => this.addElementOfSameType(event, this.props.item.id)}
				>+</span>

                {this.state.dropVisible == true &&
                    <span className="dropZone up"
                      onDrop={(event) => this.onDrop(event, 'before')}
                      data-type={this.props.item.el_sub_type}
                      //onDragLeave={(event)=>this.onDragLeave(event)}
                      //onDragOver={(event) => this.onDragOver(event)}
                    >Doleva</span>
                }

                {this.state.dropVisible == true &&
                <span className="dropZone in"
                      onDrop={(event) => this.onDropInside(event)}
                      data-type={this.props.item.el_sub_type}
                    //onDragLeave={(event)=>this.onDragLeave(event)}
                    //onDragOver={(event) => this.onDragOver(event)}
                >Vložit</span>
                }

                <span className="htmlType" 
                    draggable
                    key={"htmlType_" + this.props.item.id.toString()}
                    onDragStart= {(event: any) => this.onDragStart(event, this.props.item.id)}
                    onClick={(event) => this.onClickHandler(event, this.props.item.id)}
                    onDoubleClick={(event) => this.onDoubleClickHandler(event, this.props.item.id)}
                >{this.props.item.el_sub_type}</span>

                {this.state.dropVisible == true &&
                    <span className="dropZone down"
                          onDrop={(event) => this.onDrop(event, 'after')}
                          data-type={this.props.item.el_sub_type}
                    >Doprava</span>
                }

                {this.props.children}
                <div className="separator" key={"separator_" + this.props.item.id.toString()}></div>
                {this.state.modalVisibility == true &&
                <ModalWindow
                    title="Upravit vlastnosti"
                    toggleFunction={this.toggleWindow}
                >
                    <ElementAttributeForm item={this.props.item} toggleWindow={this.toggleWindow}/>
                </ModalWindow>
                }
            </div>
        );
    }

}
import React from 'react';

import store from '../stores/stores';
import {Item, ADD_ITEM} from '../stores/types';
import {selectItem, unSelectItem} from '../stores/actions';
import {findItemInArrayById} from '../stores/helpers';
import ModalWindow from "./modalWindow";
import ElementAttributeForm from "../forms/ElementAttributeForm";

export default class PseudoFormElement extends React.Component<any, any>
{

    public class_names: String[] = [];

    constructor(props: any) {
        super(props);

        this.state = {
            modalVisibility: false
        }

        this.setState(this.state);

        this.addClass('frm_obj');
    }

    addClass(class_name: string) {
        this.class_names = [...this.class_names, class_name];
    }

    writeText(type: string, text: string) {
        if(type == 'static') {
            return (
                <span className="name">
                    {text}
                </span>
            );
        }
        return ("");
    }

    writeMetas(id: number) {
        return (
            <span className="obj_data">
                {id}
            </span>
            );
    }

    writeLabel(label: string) {
        if(label !== "") {
            return (
                <span className="label">
                    {label}
                </span>
            )
        }
    }

    getStyle() {
        let zIndex = 30;
        if(this.state.modalVisibility == true) {
            zIndex = 9001;
        }
        return { 
            width: this.props.item.width + "px",
            height: this.props.item.height + "px",
            top: this.props.item.top + "px",
            left: this.props.item.left + "px",
            //zIndex: zIndex,
        };
    }

    getClassNames(class_names: String[] = []) {
        //console.log(this.props.item);
        class_names = [this.props.item.el_type, ...this.class_names, ...class_names];
        if(this.props.item.selected === true) {
            class_names = [...class_names, 'in_selection'];
        }
        return `${class_names.join(' ')}`;
    }

    onClickHandler = (event: any, itemId: number) => {
        if(event.ctrlKey || event.metaKey) {
            var clickedItem: Item | null = findItemInArrayById(itemId, store.getState().items.items);

            if(clickedItem !== null) {
                if(findItemInArrayById(itemId, store.getState().items.selectedItems) === null) {
                    store.dispatch(selectItem(clickedItem));
                }else {
                    store.dispatch(unSelectItem(clickedItem));
                }
            }else {
                alert('Target element not found');
            }
        }
    }

    onDoubleClickHandler = (event: any, itemId: number) => {
        this.toggleWindow(event);
    }

    toggleWindow = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        let state = { ...this.state }
        if(this.state.modalVisibility == false) {
            state.modalVisibility = true;
        }else {
            state.modalVisibility = false;
        }

        this.state = state;
        this.setState(this.state);
    }

    render() {
        
        return (
            <div className={this.getClassNames()}
                  style={this.getStyle()} 
                  data-id={this.props.item.id} 
                  onClick={(event) => this.onClickHandler(event, this.props.item.id)}
                  key={this.props.item.id.toString()} 
                  >
                {this.writeMetas(this.props.item.id)}
                {this.writeText(this.props.item.type,this.props.item.text)}
                {this.writeLabel(this.props.item.label)}

                {this.state.modalVisibility == true &&
                <ModalWindow
                    title="Upravit vlastnosti"
                    toggleFunction={this.toggleWindow}
                >
                    <ElementAttributeForm item={this.props.item} toggleWindow={this.toggleWindow}/>
                </ModalWindow>
                }
            </div>
        );

    }

}
import React from 'react';
import PseudoFormElement from './PseudoFormElement';
import store from '../stores/stores';
import {Item, ADD_ITEM} from '../stores/types';
import {addHistoryPoint, removeItem, updateItem} from '../stores/actions';
import {findItemInArrayById} from '../stores/helpers';
import ModalWindow from "./modalWindow";
import ElementAttributeForm from "../forms/ElementAttributeForm";

export default class PseudoFormElementDroppable extends PseudoFormElement
{
	constructor(props: any) {
		super(props);
		this.addClass('dropable');
	}

	onDragStart = (event: any, itemId: string) => {
		event.dataTransfer.setData("itemId", itemId);
	}

    onDragOver = (event: any) => {
		event.target.classList.add("dragover");
	    event.preventDefault();
	}

	onDragLeave =(event: any) => {
		event.target.classList.remove("dragover");
		event.preventDefault();
	}

	onDrop = (event: any) => {
		store.dispatch(addHistoryPoint());

	    let itemId = parseInt(event.dataTransfer.getData("itemId"));

		let draggItem : Item | null = findItemInArrayById(itemId, store.getState().items.items);
		

		if(draggItem !== null) {
				
			let dropItem : Item | null = findItemInArrayById(this.props.item.id, store.getState().items.items);
			
			if(dropItem !== null) {
				
				dropItem.label = draggItem.text;
				store.dispatch(updateItem(dropItem));
				store.dispatch(removeItem(draggItem));
			}else {
				alert('Cílový element nebyl nalezen');
			}

		} else {
			alert('Zdrojový element nebyl nalezen');
		}

		
		event.target.classList.remove("dragover");
	}
 
    render() {
		
        return (
            <div className={this.getClassNames()}
                  style={this.getStyle()} 
                  data-id={this.props.item.id}
				  draggable
				  onDragStart= {(event: any) => this.onDragStart(event, this.props.item.id)}
                  onDragOver={(event)=>this.onDragOver(event)}
				  onDrop={(event)=>this.onDrop(event)}
				  onDragLeave={(event)=>this.onDragLeave(event)}
				  onClick={(event) => this.onClickHandler(event, this.props.item.id)}
				  onDoubleClick={(event) => this.onDoubleClickHandler(event, this.props.item.id)}
            >
                  
                {this.writeMetas(this.props.item.id)}
                {this.writeText(this.props.item.el_type, this.props.item.text)}
				{this.writeLabel(this.props.item.label)}

				{this.state.modalVisibility == true &&
				<ModalWindow
					title="Upravit vlastnosti"
					toggleFunction={this.toggleWindow}
				>
					<ElementAttributeForm item={this.props.item} toggleWindow={this.toggleWindow}/>
				</ModalWindow>
				}
            </div>
        );

    }

}
import React from 'react';
import store from '../stores/stores';
import StructureElement from './StructureElement';

export default class InputWindow extends StructureElement {

    public class_names: String[] = [];

    constructor(props: any) {
        super(props);

        this.state = {};

        store.subscribe(() => {
            this.state = {};
            this.setState(this.state);
        });
    }

    getStyle() {
        return {
            height: this.props.item.height + "px",
        };
    }

    render() {
        return (
            <div className={this.getClassNames()} style={this.getStyle()}>
                <span className="htmlType" key={"htmlType_" + this.props.item.id.toString()}
                      onClick={(event) => this.onClickHandler(event, this.props.item.id)}
                >Input</span>
                {this.props.children}
            </div>
        );
    }

}